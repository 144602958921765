import { Cart, CartProps } from '~/components/Panels/PanelCart/types'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializePrice from '~/data/serialize-price'

export default function serializeCart(cart: Cart, locale: string): CartProps {
  const customAttributes = customAttributesAsObject(cart?.attributes ?? [])

  return {
    id: cart?.id ?? null,
    customAttributes,
    total: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalAmount?.currencyCode,
          cart?.cost?.totalAmount?.amount,
        )
      : null,
    subtotal: cart
      ? serializePrice(
          locale,
          cart?.cost?.subtotalAmount?.currencyCode,
          cart?.cost?.subtotalAmount?.amount,
        )
      : null,
    totalTax: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalTaxAmount?.currencyCode,
          cart?.cost?.totalTaxAmount?.amount,
        )
      : null,
    webUrl: cart?.checkoutUrl ?? null,
    productsQuantity: cart?.lines?.reduce?.((previousValue, lineItem) => {
      return previousValue + lineItem?.quantity ?? 0
    }, 0),
  }
}
