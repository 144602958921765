export interface SingleRouteParams {
  type: string
  href: string
}
interface SingleRoutesList {
  [key: string]: SingleRouteParams
}

export const SINGLE_ROUTES: SingleRoutesList = {
  login: {
    type: 'login_page',
    href: '/login',
  },
  register: {
    type: 'register_page',
    href: '/register',
  },
  forgotPassword: {
    type: 'forgot-password_page',
    href: '/forgot-password',
  },
  resetPassword: {
    type: 'reset-password_page',
    href: '/reset-password',
  },
  enableAccount: {
    type: 'enable_account_page',
    href: '/enable-account',
  },
  account: {
    type: 'account_page',
    href: '/account',
  },
  journal: {
    type: 'journal_page',
    href: '/journal',
  },
  wishlist: {
    type: 'wishlist_page',
    href: '/account',
  },
  search: {
    type: 'search',
    href: '/search',
  },
}

export const COOKIE_KEYS = {
  recentlyViewed: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME}-recently-viewed-cookie`,
  eventPopin: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME}-event-pop-in`,
  wishlistId: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME}-shopify-wishlist-id`,
  customerAccessToken: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME}-customer-access-token`,
}

export const ALGOLIA_HITS_PER_PAGE = 16
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product'
export const ALGOLIA_SHOPIFY_ID_KEY = 'objectID'
export const ALGOLIA_PRISMIC_ID_KEY = 'prismicID'
export const ALGOLIA_PAIRED_SUFFIX = '-paired'

export const CONTACT_EMAIL_SUBJECT = {
  fr: 'Demande de renseignements',
  'fr-fr': 'Demande de renseignements',
  en: 'Information request',
  'en-us': 'Information request',
}

export const GIFT_EMAIL_SUBJECT = {
  fr: '{{ receiverName }}, {{ giftReceiverName }} vous suggère une idée !',
  en: '{{ receiverName }}, {{ giftReceiverName }} has a gift idea!',
}

export const ASSEMBLED_PRODUCT_TYPE = 'assembled_product'
export const HALF_PRODUCT_TYPE = 'half_product'
export const PRODUCT_VARIATION_TYPE = 'product_variation'
export const PRODUCT_TYPE = 'product'
export const VARIATION_TYPE = 'variation_type'
export const SHOPIFY_ID_PRISMIC_KEY = 'shopify_product_id_eur'
export const PRODUCT_TYPES = [
  PRODUCT_TYPE,
  HALF_PRODUCT_TYPE,
  PRODUCT_VARIATION_TYPE,
]

export const TRACKING_EVENTS = {
  PAGE_VIEW: 'page_view',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  VIEW_ITEM_LIST: 'view_item_list',
  SELECT_ITEM: 'select_item',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  VIEW_CART: 'view_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  SIZE_CLIC: 'size_clic',
  SIZE_SELECTED: 'size_selected',
  SIZE_VALIDATED: 'size_validated',
  FILTER_APPLIED: 'filter_applied',
  CONTACT_FORM: 'contact_form',
  APPOINTMENT: 'appointment',
  NEWSLETTER: 'newsletter',
  INTERNAL_SEARCH: 'internal_search',
  SIZE_GUIDE_DOWNLOAD: 'sizes_guide_download',
  BEGIN_CHECKOUT: 'begin_checkout',
}

export const BRAND = 'JEM'
export const TRACKING_AFFILIATION = 'web'

export const ACTIONS = {
  SUBMIT: 'Submit',
  REGISTER: 'Register',
  LOGIN: 'Login',
  CLICK: 'Click',
  SORT_BY: 'Sort by',
  ADD: 'Add',
  REMOVE: 'Remove',
  OPEN_CONFIGURATOR: 'Open configurator',
  DL_SIZE_GUIDE: 'Download Size Guide',
}

export const PRISMIC_REST_API_URL = `https://${process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME}.cdn.prismic.io/api/v2`
export const APPOINTMENT_TIMEZONE = 'CET'
export const APPOINTMENT_TITLE_VALUE = 'Votre rendez-vous JEM'
export const APPOINTMENT_WHERE_VALUE = 'JEM Paris'
export const DEFAULT_LOCALE = 'fr'
export const DEFAULT_CURRENCY = 'eur'
export const SHOPIFY_API_VERSION = '2022-04'
export const EMAIL_ADDRESS = 'contact@jem-paris.com'
export const DYNAMIC_FORM_EMAIL_ADDRESS = 'jem@jem-paris.com'
export const FROM_EMAIL = (email: string) => `"JEM Paris" <${email}>`

export const [
  DEFAULT_UNIQUE_ID,
  PAIRED_KEY,
  WITH_ENGRAVING_KEY,
  PRODUCT_VARIATION_ATTRIBUTE_KEY,
  HREF_KEY,
  IMAGE_KEY,
  ENGRAVING_MESSAGE_KEY,
  ENGRAVING_TYPE_KEY,
  TRACKING_DATA,
] = [
  '_uniqueId',
  '_isPaired',
  '_withEngraving',
  '_productVariation',
  '_href',
  '_image',
  'product.engraving_message',
  'product.engraving_type',
  '_trackingData',
]

export const SIMPLY_BOOK_ME_API_URL = 'https://user-api-v2.simplybook.me/admin'
export const SIMPLY_BOOK_ME_COMPANY = 'jewelleryethicallyminded'
export const SIMPLY_BOOK_ME_USER = 'admin'
export const SIMPLY_BOOK_ME_SERVICE_ID = 1
export const SIMPLY_BOOK_ME_DESCRIPTION_ID = '82668d12a8a91b614700ff47d26ea33c'

export const DEFAULT_INTL = 'en-FR'
export const DEFAULT_LANG = 'fr'

export const WISHLIST_VARIATION_KEY = '__variation'

export const DOCUMENT_TYPES = {
  GLOBAL: 'global',
  NAVIGATION: 'navigation',
  FOOTER: 'footer',
  DICTIONARY: 'dictionary',
  PANELS: 'panels',
  ERROR: 'error_page',
}

export const MISSING_SHOPIFY_ID_ERROR = 'MISSING_SHOPIFY_ID'
