import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { getCustomer } from '~/lib/handle-api'
import { getAccessToken, removeAccessToken } from '~/lib/token'

import { Customer } from '@unlikelystudio/ecommerce-typed/types/shopify'

import useCurrency from '~/hooks/useCurrency'

import { fromMetafieldsToKeyValuePairs } from '~/utils/metafields'

interface UseProfileOptions {
  throwError?: boolean
  keys?: any[]
}

const defaultUseProfileOption = {
  throwError: false,
  keys: [],
}
export interface ProfileProps extends Customer {
  metafields: any
  formattedMetafields: any
}

export default function useProfile(
  options?: UseQueryOptions<any, any, any>,
  useProfileOptions?: UseProfileOptions,
) {
  const currency = useCurrency()
  const useProfileOptionsWithDefault = {
    ...defaultUseProfileOption,
    ...useProfileOptions,
  }
  return useQuery<ProfileProps | any>(
    ['getCustomer', ...useProfileOptionsWithDefault?.keys],
    async () => {
      try {
        const accessToken = getAccessToken(currency)

        if (!accessToken) return null

        const customer = await getCustomer({
          store: currency,
          accessToken,
        })

        const formattedMetafields = fromMetafieldsToKeyValuePairs(
          customer?.metafields,
        )

        return {
          ...customer,
          formattedMetafields,
          metafields: customer?.metafields?.edges?.map(({ node }) => node),
        }
      } catch (error) {
        if (useProfileOptionsWithDefault?.throwError) {
          throw new Error(error)
        }
        return null
      }
    },
    {
      ...options,
      onSuccess: (data, ...rest) => {
        if (data?.errors?.length > 0) {
          return removeAccessToken(currency)
        }

        if (options?.onSuccess) {
          options?.onSuccess(data, ...rest)
        }
      },
    },
  )
}
