import { DEFAULT_INTL } from '~/lib/constants'

import multistore from 'config/multistore.json'

import { ShopifySFLanguageCode } from '@unlikelystudio/commerce-connector'

export function getPrismicLangFromLocale(locale: string) {
  return multistore?.[locale]?.lang ?? locale
}

export function getPrismicShopifyKey(locale: string) {
  return `shopify_product_id_${multistore?.[locale]?.currency ?? 'eur'}`
}

export function getLang(locale: string) {
  const [lang] = locale?.split('-') ?? ['fr']
  return lang
}

export function getIntlLocale(locale: string) {
  return multistore?.[locale]?.intl ?? DEFAULT_INTL
}

export function getShopifyLanguageCodeFromLocale(
  locale: string,
): ShopifySFLanguageCode {
  const lang = getLang(locale)?.toUpperCase()
  return lang as ShopifySFLanguageCode
}
