import { UseQueryOptions } from '@tanstack/react-query'
import { useDebouncedCallback } from 'use-debounce'
import {
  DEFAULT_UNIQUE_ID,
  PAIRED_KEY,
  PRODUCT_VARIATION_ATTRIBUTE_KEY,
} from '~/lib/constants'

import { Checkout } from '@unlikelystudio/ecommerce-typed/types/shopify'
import {
  useGetCart as useAbstractGetCart,
  useRemoveCartLines,
  useUpdateCartLines,
} from '@unlikelystudio/react-ecommerce-hooks'
import { IGraphQLResponse } from '@unlikelystudio/simpleql'

import { CartProps } from '~/components/Panels/PanelCart/types'

import useCartProductUids from '~/hooks/checkout/useCartProductUids'
import useLocale from '~/hooks/useLocale'

import { customAttributesAsObject } from '~/utils/custom-attributes'
import { uniq } from '~/utils/uniq'

import serializeCart from '~/data/checkout-data/serialize-cart'
import { serializeCartProducts } from '~/data/checkout-data/serialize-cart-products'
import serializePrice from '~/data/serialize-price'

export type GetCheckoutMutationResponse = IGraphQLResponse<{
  node: Checkout
}>

export default function useGetCart(
  mutationOptions?: UseQueryOptions<any, any, any>,
) {
  const locale = useLocale()

  const { uids } = useCartProductUids()

  // Mutation functions
  const { mutate: updateCartLines } = useUpdateCartLines()
  const { mutate: deleteCartLines } = useRemoveCartLines()

  // Debounced functions
  const debouncedOnUpdateMutation = useDebouncedCallback(updateCartLines, 500)
  const debouncedOnDeleteMutation = useDebouncedCallback(deleteCartLines, 500)

  const {
    isSuccess,
    isError,
    data: cartPayload,
  } = useAbstractGetCart({
    refetchOnMount: false,
    onSuccess: (data) => {
      mutationOptions?.onSuccess && mutationOptions.onSuccess(data)
    },
  })

  if (!cartPayload && (isSuccess || isError)) {
    return [{ error: true } as CartProps, cartPayload] as const
  }

  // Getting the attributes
  const customAttributes = customAttributesAsObject(
    cartPayload?.attributes ?? [],
  )

  // Getting the engraving product
  const engravingProduct =
    cartPayload?.lines?.find(
      (item) => item?.merchandise?.id === customAttributes?.engravingVariantId,
    ) ?? null
  const engravingId = engravingProduct?.merchandise?.id
  const engravingsQuantity = cartPayload?.lines?.filter(
    (p) => p?.merchandise?.id === engravingId,
  )?.length
  const engravingPrice = serializePrice(
    locale,
    engravingProduct?.merchandise?.price?.currencyCode,
    engravingProduct?.merchandise?.price?.amount,
  )
  const engravingImageSrc = engravingProduct?.merchandise?.image?.url

  // Serializing cart
  const serializedCartPayload = serializeCart(cartPayload, locale)
  const serializedCartLinesPayload = serializeCartProducts(
    cartPayload?.lines,
    locale,
    uids,
  )

  // Concat what we've serialized so far
  const serializedFullCartPayload: CartProps = {
    ...serializedCartPayload,
    products: serializedCartLinesPayload,
  }

  const serializedCheckout: CartProps = {
    ...serializedFullCartPayload,
    engravingId,
    engravingsQuantity,
    engravingImageSrc,
    engravingPrice,
    productsQuantity:
      serializedFullCartPayload?.productsQuantity - engravingsQuantity,
    products: serializedFullCartPayload?.products
      ?.filter((p) => p?.variantId !== engravingId)
      ?.map((product) => {
        const id = product?.id
        const isEngraved = Boolean(product?.engraving)
        const variationId = product?.variationAttribute ?? null
        const isEngravingProduct = product?.variantId === engravingId
        const engravingSibling = serializedFullCartPayload?.products?.find(
          (p) => {
            return (
              (p?.uniqueId === product?.uniqueId ||
                p?.uniqueId === product?.variationAttribute) &&
              p?.variantId === engravingId
            )
          },
        )

        const engravingPayload = {
          id: engravingSibling?.id,
          quantity: 0,
        }

        const restoredAttributes = []

        if (variationId) {
          restoredAttributes.push({
            key: PRODUCT_VARIATION_ATTRIBUTE_KEY,
            value: variationId,
          })
        } else {
          restoredAttributes.push({
            key: DEFAULT_UNIQUE_ID,
            value: product?.uniqueId,
          })
        }

        if (product?.isPaired) {
          restoredAttributes.push({
            key: PAIRED_KEY,
            value: 'true',
          })
        }

        return {
          ...product,
          isEngravingProduct,
          onDelete: () => {
            if (id) {
              debouncedOnDeleteMutation.cancel()

              const ids = [
                id,
                ...(isEngraved && engravingSibling
                  ? [engravingSibling?.id]
                  : []),
                ...(product?.isPaired ? [id] : []),
              ]

              const uniqueIds = uniq(ids)

              // The remove doesn't work if the engraving is still in the cart, I had to use the updateCartLines function
              updateCartLines(uniqueIds?.map((id) => ({ id, quantity: 0 })))
            }
          },
          onDeleteEngraving: () => {
            if (id && isEngraved) {
              debouncedOnUpdateMutation.cancel()

              updateCartLines([
                engravingPayload,
                {
                  id,
                  attributes: restoredAttributes,
                },
              ])
            }
          },
          ...(variationId && {
            onVariationDelete: (ids: string[]) => {
              const sibling = serializedFullCartPayload?.products?.find(
                (p) => p?.variationAttribute === variationId,
              )
              const variationIsEngraved = sibling?.engraving || isEngraved

              if (ids) {
                debouncedOnDeleteMutation.cancel()
                const uniqueIds = uniq(ids)

                deleteCartLines(uniqueIds, {
                  onSuccess: () => {
                    if (variationIsEngraved) {
                      updateCartLines([engravingPayload])
                    }
                  },
                })
              }
            },
          }),
        }
      }),
  }

  return [serializedCheckout, cartPayload] as const
}
