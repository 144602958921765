import classnames from 'classnames/bind'
import { AnimatePresence } from 'framer-motion'
import { useCallback, useRef, useState } from 'react'

import {
  useIsomorphicLayoutEffect,
  useMeasure,
  useOnResize,
} from '@unlikelystudio/react-hooks'

import LangSwitcher from '~/components/LangSwitcher'
import { NavigationProps } from '~/components/Navigation'
import DesktopPanel, {
  SideImageState,
} from '~/components/Navigation/Desktop/DesktopPanel'
import DesktopPanelImage from '~/components/Navigation/Desktop/DesktopPanelImage'
import NavigationLink from '~/components/Navigation/common/NavigationLink'
import NavigationLogo from '~/components/Navigation/common/NavigationLogo'
import { PanelCart, PanelContact, PanelLogin } from '~/components/Panels'
import { AccountIcon, BagIcon, HeartIcon, SearchIcon } from '~/components/icons'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useGetCart from '~/hooks/checkout/useGetCart'
import useOnKeyDown from '~/hooks/useOnKeyDown'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export default function DesktopHeader({
  className,
  logo,
  headerLight,
  withBorder,
  headerLeft,
  searchLink,
  accountLink,
  contactLink,
  wishlistLink,
  isLogged,
}: NavigationProps) {
  const [cart] = useGetCart()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [panelIndex, setPanelIndex] = useState<number | null>(null)
  const [sideImage, setSideImage] = useState<SideImageState | null>(null)
  const headerRef = useRef(null)

  const gridStyle = useStyle({ grid: 'base-grid' })
  const cartQuantityStyle = useStyle({
    textPreset: 'text-8',
    color: 'gray-20',
  })
  const { height } = useMeasure(headerRef)
  const { add } = usePanel()

  useIsomorphicLayoutEffect(() => {
    if (!isOpen) setPanelIndex(null)
  }, [isOpen])

  const toggleMenu = () => setIsOpen((prevState) => !prevState)

  const hideMenu = () => {
    setIsOpen(false)
    setPanelIndex(null)
  }

  const closeOnCallback = useCallback(() => {
    if (isOpen) toggleMenu()
  }, [isOpen])

  useOnResize(closeOnCallback)

  useOnKeyDown(27, closeOnCallback)

  const rows = headerLeft?.[panelIndex]?.rows

  return (
    <>
      <div
        className={cx(css.underline, className, { headerLight, withBorder })}
      />
      <div ref={headerRef} className={cx(className, css.DesktopHeader)}>
        <div className={cx(gridStyle, css.container)}>
          <ul className={css.headerLeft}>
            {headerLeft?.map((item, index) => {
              const hasRows = item?.rows?.length > 0
              const { isDirectLink } = item

              return (
                <li className={css.link} key={`headerLeftLink${index}`}>
                  <NavigationLink
                    handleClick={(e) => {
                      if (isDirectLink) return
                      if (hasRows) {
                        e.preventDefault()
                        e.stopPropagation()
                        if (isOpen) setPanelIndex(null)
                        else setPanelIndex(index)

                        toggleMenu()
                      }
                    }}
                    forceUnderline={panelIndex === index && !isDirectLink}
                    handleMouseEnter={() => {
                      if (isOpen && !isDirectLink) setPanelIndex(index)
                    }}
                    className={css.inlineCta}
                    type="primary"
                    link={item?.link}
                  />
                </li>
              )
            })}
          </ul>
          <NavigationLogo className={css.logoWrapper} {...logo} />
          <ul className={css.headerRight}>
            {contactLink && (
              <li className={css.link}>
                <NavigationLink
                  aria-label="Link to contact form"
                  className={css.inlineCta}
                  type="primary"
                  link={contactLink}
                  handleClick={(e) => {
                    e.preventDefault()
                    add({ component: <PanelContact /> })
                  }}
                />
              </li>
            )}
            {searchLink && (
              <li className={cx(css.link, css.iconLink)}>
                <NavigationLink
                  aria-label="Link to search page"
                  className={css.inlineCta}
                  type="primary"
                  link={{ ...searchLink, children: <SearchIcon /> }}
                />
              </li>
            )}
            <li className={cx(css.link, css.iconLink)}>
              <NavigationLink
                aria-label="Link to account page"
                className={css.inlineCta}
                type="primary"
                link={{ ...accountLink, children: <AccountIcon /> }}
                handleClick={(e) => {
                  if (!isLogged) {
                    e.preventDefault()
                    add({ component: <PanelLogin /> })
                  }
                }}
              />
            </li>
            {wishlistLink && (
              <li className={cx(css.link, css.iconLink)}>
                <NavigationLink
                  aria-label="Link to wishlist"
                  className={css.inlineCta}
                  type="primary"
                  link={{
                    ...wishlistLink,
                    children: <HeartIcon {...wishlistLink} />,
                  }}
                />
              </li>
            )}
            <li className={cx(css.link, css.iconLink)}>
              <NavigationLink
                aria-label="Link to wishlist"
                className={css.inlineCta}
                type="primary"
                handleClick={(e) => {
                  e.preventDefault()
                  add({ component: <PanelCart /> })
                }}
                link={{
                  ...accountLink,
                  children: (
                    <>
                      {cart.productsQuantity > 0 && (
                        <span
                          className={cx(cartQuantityStyle, css.cartQuantity)}>
                          <span>{cart.productsQuantity}</span>
                        </span>
                      )}
                      <BagIcon />
                    </>
                  ),
                }}
              />
            </li>
            <li className={cx(css.langSwitcher, css.link)}>
              <LangSwitcher />
            </li>
          </ul>
        </div>
      </div>
      <AnimatePresence>
        {sideImage && (
          <DesktopPanelImage
            {...rows?.[sideImage?.row]?.links?.[sideImage?.item]?.image}
          />
        )}
      </AnimatePresence>
      <DesktopPanel
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        hideMenu={hideMenu}
        rows={rows}
        headerHeight={height}
        sideImage={sideImage}
        setSideImage={setSideImage}
      />
    </>
  )
}

DesktopHeader.defaultProps = {}
