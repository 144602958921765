import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { ReactNode } from 'react'
import { PRODUCT_VARIATION_TYPE, TRACKING_EVENTS } from '~/lib/constants'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import AlertCenter from '~/components/AlertCenter'
import ConditionalCrawlerDetector from '~/components/ConditionalCrawlerDetector'
import EventPopIn from '~/components/EventPopIn'
import Footer from '~/components/Footer'
import Meta from '~/components/Meta'
import Navigation from '~/components/Navigation'
import Newsletter from '~/components/Newsletter'
import PanelManager from '~/components/Panels'
import Reassurance from '~/components/Reassurance'

import { useCssVariables } from '~/providers/CssVariablesProvider'
import { useTracking } from '~/providers/TrackingProvider'

import useGTMTracking from '~/hooks/tracking/useGTMTracking'
import use100vh from '~/hooks/use100vh'
import useFixScrollbarOverflow from '~/hooks/useFixScrollbarOverflow'
import useOnRouteChange from '~/hooks/useOnRouteChange'

import { DefaultPageData } from '~/data/page-data/serializer'

import css from './styles.module.scss'

const ConfiguratorManager = dynamic(
  () => import('~/components/ConfiguratorManager'),
)
const CookiesPopin = dynamic(() => import('~/components/CookiesPopin'))

const cx = classnames.bind(css)

export interface PageProps extends DefaultPageData {
  children?: ReactNode | ReactNode[]
}
function Page({ children, metas, global, options, configurator }: PageProps) {
  useGTMTracking()
  useFixScrollbarOverflow({ maxGridWidth: parseInt(css?.maxGridWidth) })
  use100vh()

  const tracking = useTracking()
  const { variables: cssVariables } = useCssVariables()

  useOnRouteChange(() => tracking.emit(TRACKING_EVENTS.PAGE_VIEW))

  useIsomorphicLayoutEffect(() => {
    const body = document.querySelector('html')
    const reduced = Object.keys(cssVariables).reduce(
      (prev, key) => prev + `--${key} : ${cssVariables[key]}; `,
      '',
    )
    body.setAttribute('style', reduced)
  }, [{ ...cssVariables }])

  const isHomepage = metas?.type === 'home_page'
  const isProductVariation = metas?.type === PRODUCT_VARIATION_TYPE
  const displayBanner = isHomepage && global?.banner?.displayed
  const displayEventPopin = isHomepage && global?.eventPopIn?.displayed

  return (
    <>
      <Meta {...metas} />
      <Navigation
        banner={{
          display: displayBanner,
          ...global?.banner,
        }}
        headerLight={options?.headerLight}
        withBorder={options?.withBorder}
        {...global?.navigation}
      />
      <main className={cx(css.Page)}>
        <>
          {children}
          {options?.displayReassurance && (
            <Reassurance withSlider {...global?.reassurance} />
          )}
          {options?.displayNewsletter && <Newsletter {...global?.newsletter} />}
          <ConditionalCrawlerDetector>
            {displayEventPopin && (
              <EventPopIn
                {...global?.eventPopIn}
                displayed={displayEventPopin}
              />
            )}
            <PanelManager />
            <AlertCenter />
            <CookiesPopin />
          </ConditionalCrawlerDetector>
          {isProductVariation && configurator && (
            <ConfiguratorManager {...configurator} />
          )}
        </>
        <Footer {...global?.footer} />
      </main>
    </>
  )
}

Page.defaultProps = {}

export default Page
