import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ReactNode, useMemo } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { I18n } from 'react-polyglot'
import { DEFAULT_LANG } from '~/lib/constants'
import { getMultistoreCredentials } from '~/lib/get-multistore-token'

import {
  ShopifyCurrencyCode,
  ShopifySFLanguageCode,
} from '@unlikelystudio/commerce-connector'
import {
  CartProvider,
  CustomerProvider,
  StoreProvider,
} from '@unlikelystudio/react-ecommerce-hooks'

import AlertProvider from '~/providers/AlertProvider'
import BreadcrumbProvider from '~/providers/BreadcrumbProvider'
import ClickOutsideProvider from '~/providers/ClickOutsideProvider'
import ConfiguratorProvider from '~/providers/ConfiguratorProvider'
import CookiesProvider from '~/providers/CookiesProvider'
import CssVariablesProvider from '~/providers/CssVariablesProvider'
import FiltersProvider from '~/providers/FiltersProvider'
import GlobalDataProvider from '~/providers/GlobalDataProvider'
import NavigationProvider from '~/providers/NavigationProvider'
import PanelProvider from '~/providers/PanelProvider'
import StyleProvider from '~/providers/StyleProvider'
import ThemeProvider from '~/providers/ThemeProvider'
import TrackingProvider from '~/providers/TrackingProvider'
import WishlistProvider from '~/providers/WishlistProvider'

import useCurrency from '~/hooks/useCurrency'
import ProductsViewedProvider from '~/hooks/useProductsRecentlyViewed/context'

import { getLang } from '~/utils/locales'

import { DefaultPageData } from '~/data/page-data/serializer'

import gridPresets from '~/styles/grid-presets/styles.module.scss'
import ratioPresets from '~/styles/ratio-presets/styles.module.scss'
import textPresets from '~/styles/text-presets/styles.module.scss'
import backgroundColors from '~/styles/theme-classes/background-color.module.scss'
import borderColors from '~/styles/theme-classes/border-color.module.scss'
import colors from '~/styles/theme-classes/colors.module.scss'
import themePresets from '~/styles/theme-presets/styles.module.scss'

export interface AppProviderProps {
  children: ReactNode | ReactNode[]
  pageProps: DefaultPageData
}

const stylePresets = {
  textPreset: textPresets,
  color: colors,
  grid: gridPresets,
  ratio: ratioPresets,
  backgroundColor: backgroundColors,
  borderColor: borderColors,
}

const queryClient = new QueryClient()

function AppProvider({ children, pageProps }: AppProviderProps) {
  const { locale } = useRouter()

  const storeData = getMultistoreCredentials(locale)

  const memoizedGlobalProviderData = useMemo(
    () => ({
      ...pageProps?.globalData,
      global: {
        productReassurance: pageProps?.global?.productReassurance,
        deliveryTime: pageProps?.global?.deliveryTime,
        cookies: pageProps?.global?.cookies,
      },
    }),
    [pageProps?.globalData],
  )

  const memoizedMetaData = useMemo(
    () => ({
      metas: pageProps?.metas,
    }),
    [locale, pageProps?.metas],
  )

  const lang = ((getLang(locale) ?? DEFAULT_LANG) as string)?.toUpperCase()
  const currency = useCurrency()

  return (
    <QueryClientProvider client={queryClient}>
      <I18n
        locale={locale}
        messages={pageProps?.global?.dictionary}
        allowMissing={true}>
        <TrackingProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_API_KEY}
            scriptProps={{
              async: true,
              defer: true,
            }}>
            <StoreProvider
              language={lang as ShopifySFLanguageCode}
              currency={currency as ShopifyCurrencyCode}
              storefrontAccessToken={storeData?.storeFrontAccessToken}
              storeUrl={storeData?.url}
              locale={locale}>
              <CartProvider>
                <CustomerProvider>
                  <GlobalDataProvider
                    value={{
                      ...memoizedGlobalProviderData,
                      ...memoizedMetaData,
                    }}>
                    <StyleProvider value={stylePresets}>
                      <CookiesProvider>
                        <CssVariablesProvider
                          value={{ 'scroll-bar': '0px', vh100: '100vh' }}>
                          <ThemeProvider value={themePresets}>
                            <ConfiguratorProvider>
                              <AlertProvider>
                                <FiltersProvider>
                                  <PanelProvider
                                    data={pageProps?.global?.panels}>
                                    <BreadcrumbProvider
                                      links={pageProps?.breadcrumb}>
                                      <ProductsViewedProvider>
                                        <NavigationProvider>
                                          <WishlistProvider>
                                            <ClickOutsideProvider>
                                              {children}
                                            </ClickOutsideProvider>
                                          </WishlistProvider>
                                        </NavigationProvider>
                                      </ProductsViewedProvider>
                                    </BreadcrumbProvider>
                                  </PanelProvider>
                                </FiltersProvider>
                              </AlertProvider>
                            </ConfiguratorProvider>
                          </ThemeProvider>
                        </CssVariablesProvider>
                      </CookiesProvider>
                    </StyleProvider>
                  </GlobalDataProvider>
                </CustomerProvider>
              </CartProvider>
            </StoreProvider>
          </GoogleReCaptchaProvider>
        </TrackingProvider>
      </I18n>
    </QueryClientProvider>
  )
}

AppProvider.defaultProps = {}

export default AppProvider
