import jwtDecode from 'jwt-decode'
import jwtEncode from 'jwt-encode'

import { GetShopifyStorefrontClientOptions } from '@unlikelystudio/commerce-connector'

import multistore from '../../config/multistore.json'
import { DEFAULT_LOCALE } from '../lib/constants'

const SECRET = 'secret'
const STRING_TO_REPLACE = '{adminAccessToken}'

type MultistoreTokenOptions = {
  url: GetShopifyStorefrontClientOptions['storeUrl']
  storeFrontAccessToken: GetShopifyStorefrontClientOptions['storefrontAccessToken']
  adminAccessToken?: string
  wsaToken?: string
  main: boolean
  version: string
  apiBase: string
  fromContact: string
  name: string
  devise: string
  webhookSignature: string
}

export interface MultistoreToken {
  [key: string]: MultistoreTokenOptions
}

/**
 * @returns hash token
 * This method allows us to reconstruct the admin token
 * In order to avoid storing 2 env variables with redundant data
 */
export default function getMultistoreToken() {
  const hashedAdminToken = process.env.SHOPIFY_MULTISTORE_TOKEN
  const hashedPublicToken = process.env.NEXT_PUBLIC_SHOPIFY_MULTISTORE_TOKEN

  if (!hashedAdminToken) return hashedPublicToken

  const adminToken = jwtDecode(hashedAdminToken)
  const publicToken = jwtDecode(hashedPublicToken)

  const data = Object.keys(publicToken)?.reduce((obj, key) => {
    const admin = adminToken[key]
    admin.apiBase = admin?.apiBase?.replace(
      STRING_TO_REPLACE,
      admin?.adminAccessToken,
    )

    return {
      ...obj,
      [key]: {
        ...publicToken[key],
        ...adminToken[key],
      },
    }
  }, {})

  const token = jwtEncode(data, SECRET)

  return token
}

/**
 * @returns multistore object
 */
export function getMultistoreCredentials(locale: string) {
  const currency = multistore[locale ?? DEFAULT_LOCALE]?.currency
  const encodedToken = getMultistoreToken()
  const token: MultistoreToken = encodedToken ? jwtDecode(encodedToken) : null

  return token?.[currency] ?? null
}
