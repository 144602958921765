import {
  DEFAULT_UNIQUE_ID,
  ENGRAVING_MESSAGE_KEY,
  ENGRAVING_TYPE_KEY,
  HREF_KEY,
  IMAGE_KEY,
  PAIRED_KEY,
  PRODUCT_VARIATION_ATTRIBUTE_KEY,
  TRACKING_DATA,
  WITH_ENGRAVING_KEY,
} from '~/lib/constants'
import linkResolver from '~/lib/link-resolver'

import { Cart, CartProduct } from '~/components/Panels/PanelCart/types'

import { CartProductUidsCookie } from '~/hooks/checkout/useCartProductUids'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializePrice from '~/data/serialize-price'

export function serializeCartProducts(
  products: Cart['lines'],
  locale: string,
  uids: CartProductUidsCookie,
): CartProduct[] {
  return products?.map((node) => {
    const cookieData =
      uids?.[locale]?.find(({ id }) => id === node?.merchandise?.product?.id) ??
      null

    const formattedCustomAttributes = customAttributesAsObject(
      node?.attributes ?? [],
    )

    const withEngraving = Boolean(
      formattedCustomAttributes?.[WITH_ENGRAVING_KEY],
    )

    const isPaired = Boolean(formattedCustomAttributes?.[PAIRED_KEY])

    const variationAttribute =
      formattedCustomAttributes?.[PRODUCT_VARIATION_ATTRIBUTE_KEY] ?? null
    const hrefAttribute = formattedCustomAttributes?.[HREF_KEY] ?? null
    const imageAttribute = formattedCustomAttributes?.[IMAGE_KEY] ?? null

    return {
      id: node?.id,
      href: hrefAttribute,
      isPaired,
      trackingData: formattedCustomAttributes?.[TRACKING_DATA]
        ? {
            ...JSON.parse(formattedCustomAttributes?.[TRACKING_DATA]),
            quantity: node?.quantity,
          }
        : null,
      uniqueId: formattedCustomAttributes?.[DEFAULT_UNIQUE_ID],
      variantId: node?.merchandise?.id,
      price: serializePrice(
        locale,
        node?.merchandise?.price?.currencyCode,
        node?.merchandise?.price?.amount,
      ),
      priceAmount: `${node?.merchandise?.price?.amount}` ?? null,
      title: node?.merchandise?.product?.title,
      size: node?.merchandise?.title,
      quantity: node?.quantity,
      imageSrc: node?.merchandise?.image?.url ?? imageAttribute ?? null,
      ...(cookieData && {
        href: linkResolver({
          type: 'product',
          lang: locale,
          uid: cookieData?.uid,
        }),
      }),
      ...(variationAttribute && {
        variationAttribute,
      }),
      ...(withEngraving && {
        engraving: {
          [ENGRAVING_TYPE_KEY]:
            formattedCustomAttributes[`_${ENGRAVING_TYPE_KEY}`],
          [ENGRAVING_MESSAGE_KEY]:
            formattedCustomAttributes[`_${ENGRAVING_MESSAGE_KEY}`],
        },
      }),
    }
  })
}
